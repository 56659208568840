@font-face {
  font-family: "Colfax";
  src: local("Colfax"), url(../fonts/Colfax/Colfax-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Colfax Black";
  src: local("Colfax-Black"), url(../fonts/Colfax/Colfax-Black.otf) format("truetype");
}

@font-face {
  font-family: "Colfax Bold";
  src: local("Colfax-Bold"), url(../fonts/Colfax/Colfax-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Colfax Regular";
  src: local("Colfax-Regular"), url(../fonts/Colfax/Colfax-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Colfax Medium";
  src: local("Colfax-Medium"), url(../fonts/Colfax/Colfax-Medium.otf) format("truetype");
}

@font-face {
  font-family: "Colfax Medium Italic";
  src: local("Colfax-MediumItalic"), url(../fonts/Colfax/Colfax-MediumItalic.otf) format("truetype");
}

@font-face {
  font-family: "Colfax Light";
  src: local("Colfax-Light"), url(../fonts/Colfax/Colfax-Light.otf) format("truetype");
}

@font-face {
  font-family: "Colfax Thin";
  src: local("Colfax-Thin"), url(../fonts/Colfax/Colfax-Thin.otf) format("truetype");
}

html {
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* h2 {
  font-size: 55px;
} */
