@import "./Helpers.scss";
@import "./Mixins.scss";
@import "./Pams.scss";

.App {
  padding-top: 56px;
  @include respond-to(medium) {
    padding-top: 130px;
  }
  text-align: center;
  font-family: "Colfax";
  height: 100vh;

  #address-lookup::placeholder {
    opacity: 1;
  }

  .MuiButton-contained.Mui-disabled {
    opacity: 0.5;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 6px;
    height: 45px;
    @include respond-to(medium) {
      padding: 9px;
      height: 75px;
      margin-top: 0;
    }
  }

  .MuiTooltip-popper[data-popper-placement*="top"] .css-1k51tf5-MuiTooltip-tooltip {
    .MuiTooltip-arrow {
      margin-top: -36px !important;
      margin-left: 10px !important;
    }
  }
  .MuiTooltip-popper[data-popper-placement*="bottom"] .css-1k51tf5-MuiTooltip-tooltip {
    .MuiTooltip-arrow {
      margin-top: -36px !important;
      margin-left: -10px !important;
    }
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 35px;
  }

  .MuiOutlinedInput.notchedOutline {
    border-color: red !important;
  }

  .MuiInputBase-input::placeholder {
    color: #b6c8dd;
  }

  // .top_section .MuiSelect-root {
  //   span:after {
  //     content: "Age of roof";
  //     color: #000000;
  //   }
  // }

  .top_section > div .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }

  .top_section .address_input {
    max-width: 470px;
  }

  .MuiAppBar-root {
    z-index: 5000;
  }

  // TYPOGRAPHY
  h1 {
    font-size: 20px;
    line-height: 25px;
    @include respond-to(medium) {
      font-size: 45px;
      line-height: 42px;
    }
  }

  .single-article {
    a {
      font-size: 14px;
      @include respond-to(small) {
        font-size: 16px;
      }
    }
    img {
      width: 100%;
      border-radius: 10px;
    }

    figure {
      width: 100%;
      margin: 0;
    }

    strong {
      font-weight: normal;
    }

    h2,
    h3 {
      font-family: "Colfax Medium";
    }
    h2 {
      font-size: 30px;
    }

    h3 {
      font-size: 24px;
    }

    p {
      // display: inline;
      font-size: 16px;
      line-height: 35px;
      font-family: "Colfax Regular";
      a {
        color: #0e5cbf;
        font-family: Colfax Medium;
        font-size: 16px;
      }
      strong {
        font-family: Colfax Medium;
      }
    }
    ul {
      li {
        line-height: 35px;
      }
    }
  }

  .reporting {
    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
    }

    th,
    td {
      padding: 15px;
    }
  }

  .Admin {
    padding-top: 122px;
  }
}

ul[role="listbox"] {
  padding: 0 !important;
}

#menu- {
  z-index: 5000 !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  z-index: 5001 !important;
}

.MuiAutocomplete-noOptions {
  visibility: hidden;
  &::after {
    content: "Avoid abbreviations";
    position: absolute;
    left: 15px;
    visibility: visible;
  }
}

iframe {
  display: none;
}
